import React from "react";
import { Link, graphql } from "gatsby";

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import Section from '../../components/shared/Section/Section';
import PageHeader from '../../components/shared/PageHeader/PageHeader';
import ArticleList from '../../components/shared/ArticleList/ArticleList';
import ArticleListItem from '../../components/shared/ArticleList/ArticleListItem';

// Style Imports
import './TagListing.scss';

const TagListing = ({
  pageContext,
  data,
  ...props
}) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`;

  return (
    <Default
      location={props.location}
      title='Tags'
      heading={tagHeader}
    >
      <SEO
        title='Tags'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading={`${totalCount} post${totalCount === 1 ? "" : "s"} tagged with "${tag}"`}
      />

      <Section>
        {edges && edges.length && (
          <ArticleList>
            {edges.map((item, index) => {
              return (
                <ArticleListItem
                  date={item.node.frontmatter.date}
                  title={item.node.frontmatter.title}
                  description={item.node.frontmatter.description}
                  slug={item.node.frontmatter.path}
                  author={item.node.frontmatter.author}
                  key={index}
                />
              );
            })}
          </ArticleList>
        )}

        <div className='tag-listing__button-wrapper'>
          <Link className='tag-listing__button' to="/blog/tags">View all tags</Link>
        </div>
      </Section>

    </Default>
  );
};

export default TagListing;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            author
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`